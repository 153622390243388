<template>
  <div class="fullScreen" :class="{ show: true }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> 全局配置</el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain">
      <div>
        <span></span>
      </div>
      <el-tabs v-model="activeName" class="fullScreenMainCon" @tab-click="handleClick">
        <!-- 规则 -->
        <el-tab-pane label="商务费用管理" name="商务费用管理">
          <Reimbursement
            :rulesType="rulesType"
            :limitType="limitType"
            :ReimbursementModule="ReimbursementModule"
            :ReimbursementList="ReimbursementList"
            :usedReimbursementList="usedReimbursementList"
            ref="eeimbursement"
          ></Reimbursement
        ></el-tab-pane>
        <el-tab-pane label="项目费用管理" name="项目费用管理">
          <RequestFund
            :rulesType="rulesType"
            :limitType="limitType"
            :RequestFundModule="RequestFundModule"
            :RequestFundList="RequestFundList"
            :usedRequestFundList="usedRequestFundList"
            ref="requestFund"
          ></RequestFund>
        </el-tab-pane>
        <el-tab-pane label="公共费用管理" name="公共费用管理">
          <PublicCost
            :rulesType="rulesType"
            :limitType="limitType"
            :PublicCostModule="PublicCostModule"
            :PublicCostList="PublicCostList"
            :usedPublicCostList="usedPublicCostList"
            ref="publicCost"
          ></PublicCost>
        </el-tab-pane>
      </el-tabs>

      <div class="fullScreenOperation" v-if="permission(['PERFORMANCE_RULES_EDIT'])">
        <el-button type="primary" @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
    Reimbursement: () => import("./Reimbursement.vue"),
    RequestFund: () => import("./RequestFund.vue"),
    PublicCost: () => import("./PublicCost.vue"),
  },
  props: {},
  data() {
    return {
      activeName: "商务费用管理",
      ReimbursementList: [], //商务费用管理-旧报销管理
      RequestFundList: [], //项目费用管理-旧请款管理
      PublicCostList: [], //公共费用管理
      usedReimbursementList: [], //商务费用管理
      usedRequestFundList: [], //项目费用管理
      usedPublicCostList: [], //公共费用管理
      ReimbursementModule: { module: "", ruleList: [] }, //商务费用管理
      RequestFundModule: { module: "", ruleList: [] }, //项目费用管理
      PublicCostModule: { module: "", ruleList: [] }, //公共费用管理
      rulesType: [],
      limitType: [],
      oldData: "商务费用管理",
      loading: false,
      source: 0,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created() {
    this.$api.dict
      .listSysDictData("LIMIT_CONFIGURATION_TYPE", true)
      .then((res) => {
        this.limitType = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.init();
  },
  methods: {
    init() {
      this.$api.dict
        .listSysDictData("LIMIT_CONFIGURATION_MANAGE", true)
        .then((res) => {
          this.rulesType = res.data;
          res.data.forEach((v) => {
            if (v.dictName == "商务费用管理") {
              this.ReimbursementModule.module = v.dictVal;
              this.$api.limitConfiguration
                .limitConfigurationList({
                  module: v.dictVal,
                })
                .then((res) => {
                  if (res.data.ruleList) {
                    const result = {};
                    for (let i = 0; i < res.data.ruleList.length; i++) {
                      const item = res.data.ruleList[i];
                      item.amount = item.amount.toLocaleString();
                      const name = item.name;
                      if (!result[name]) {
                        result[name] = [];
                      }
                      result[name].push(item);
                    }
                    for (const name in result) {
                      this.ReimbursementList.push({ name: name, ruleList: result[name] });
                    }
                    this.usedReimbursementList = this.ReimbursementList.deepClone();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else if (v.dictName == "项目费用管理") {
              this.RequestFundModule.module = v.dictVal;
              this.$api.limitConfiguration
                .limitConfigurationList({
                  module: v.dictVal,
                })
                .then((res) => {
                  if (res.data.ruleList) {
                    const result = {};
                    for (let i = 0; i < res.data.ruleList.length; i++) {
                      const item = res.data.ruleList[i];
                      item.amount = item.amount.toLocaleString();
                      const name = item.name;
                      if (!result[name]) {
                        result[name] = [];
                      }
                      result[name].push(item);
                    }
                    for (const name in result) {
                      this.RequestFundList.push({ name: name, ruleList: result[name] });
                    }
                    this.usedRequestFundList = this.RequestFundList.deepClone();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else if (v.dictName == "公共费用管理") {
              this.PublicCostModule.module = v.dictVal;
              this.$api.limitConfiguration
                .limitConfigurationList({
                  module: v.dictVal,
                })
                .then((res) => {
                  if (res.data.ruleList) {
                    const result = {};
                    for (let i = 0; i < res.data.ruleList.length; i++) {
                      const item = res.data.ruleList[i];
                      item.amount = item.amount.toLocaleString();
                      const name = item.name;
                      if (!result[name]) {
                        result[name] = [];
                      }
                      result[name].push(item);
                    }
                    for (const name in result) {
                      this.PublicCostList.push({ name: name, ruleList: result[name] });
                    }
                    this.usedPublicCostList = this.PublicCostList.deepClone();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      this.$confirm(`是否要修改${this.activeName}页面`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;

          let Reimbursement = { module: this.ReimbursementModule.module, ruleList: [] }; //商务费用管理
          let RequestFund = { module: this.RequestFundModule.module, ruleList: [] }; //项目费用管理
          let PublicCost = { module: this.PublicCostModule.module, ruleList: [] }; //公共费用管理
          if (this.activeName == "商务费用管理") {
            this.rulesType.forEach((v) => {
              if (v.dictName == this.activeName) {
                this.ReimbursementList.forEach((v) => {
                  v.ruleList.forEach((item) => {
                    if (item.amount) {
                      item.amount = item.amount.replace(/,/g, "");
                    }
                    Reimbursement.ruleList.push(item);
                  });
                });
                this.$api.limitConfiguration
                  .limitConfigurationEdit(Reimbursement)
                  .then((res) => {
                    this.$api.limitConfiguration
                      .limitConfigurationList({
                        module: v.dictVal,
                      })
                      .then((res) => {
                        if (res.data.ruleList) {
                          this.ReimbursementList = [];
                          this.usedReimbursementList = [];
                          const result = {};
                          for (let i = 0; i < res.data.ruleList.length; i++) {
                            const item = res.data.ruleList[i];
                            item.amount = item.amount.toLocaleString();
                            const name = item.name;
                            if (!result[name]) {
                              result[name] = [];
                            }
                            result[name].push(item);
                          }
                          for (const name in result) {
                            this.ReimbursementList.push({ name: name, ruleList: result[name] });
                          }
                          this.usedReimbursementList = this.ReimbursementList.deepClone();
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        this.loading = false;
                      });
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                    this.loading = false;
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              }
            });
            return;
          }

          if (this.activeName == "项目费用管理") {
            this.rulesType.forEach((v) => {
              if (v.dictName == this.activeName) {
                this.RequestFundList.forEach((v) => {
                  v.ruleList.forEach((item) => {
                    if (item.amount) {
                      item.amount = item.amount.replace(/,/g, "");
                    }
                    RequestFund.ruleList.push(item);
                  });
                });

                this.$api.limitConfiguration
                  .limitConfigurationEdit(RequestFund)
                  .then((res) => {
                    this.$api.limitConfiguration
                      .limitConfigurationList({
                        module: v.dictVal,
                      })
                      .then((res) => {
                        if (res.data.ruleList) {
                          this.RequestFundList = [];
                          this.usedRequestFundList = [];
                          const result = {};
                          for (let i = 0; i < res.data.ruleList.length; i++) {
                            const item = res.data.ruleList[i];
                            item.amount = item.amount.toLocaleString();
                            const name = item.name;
                            if (!result[name]) {
                              result[name] = [];
                            }
                            result[name].push(item);
                          }
                          for (const name in result) {
                            this.RequestFundList.push({ name: name, ruleList: result[name] });
                          }
                          this.usedRequestFundList = this.RequestFundList.deepClone();
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        this.loading = false;
                      });
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });

                    this.loading = false;
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              }
            });
            return;
          }

          if (this.activeName == "公共费用管理") {
            this.rulesType.forEach((v) => {
              if (v.dictName == this.activeName) {
                this.PublicCostList.forEach((v) => {
                  v.ruleList.forEach((item) => {
                    if (item.amount) {
                      item.amount = item.amount.replace(/,/g, "");
                    }
                    PublicCost.ruleList.push(item);
                  });
                });
                this.$api.limitConfiguration
                  .limitConfigurationEdit(PublicCost)
                  .then((res) => {
                    this.$api.limitConfiguration
                      .limitConfigurationList({
                        module: v.dictVal,
                      })
                      .then((res) => {
                        if (res.data.ruleList) {
                          this.PublicCostList = [];
                          this.usedPublicCostList = [];
                          const result = {};
                          for (let i = 0; i < res.data.ruleList.length; i++) {
                            const item = res.data.ruleList[i];
                            item.amount = item.amount.toLocaleString();
                            const name = item.name;
                            if (!result[name]) {
                              result[name] = [];
                            }
                            result[name].push(item);
                          }
                          for (const name in result) {
                            this.PublicCostList.push({ name: name, ruleList: result[name] });
                          }
                          this.usedPublicCostList = this.PublicCostList.deepClone();
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        this.loading = false;
                      });
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                    this.loading = false;
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              }
            });
            return;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "您已取消修改",
          });
          this.loading = false;
        });
    },
    handleClick() {
      let ReimbursementisShow = false;
      let RequestFundisShow = false;
      let PublicCostisShow = false;
      if (this.oldData == "商务费用管理") {
        try {
          this.usedReimbursementList.forEach((v) => {
            this.ReimbursementList.forEach((k) => {
              v.ruleList.forEach((a1) => {
                k.ruleList.forEach((a2) => {
                  if (a1.id == a2.id) {
                    const num1 = Number(String(a1.amount).replace(/,/g, ""));
                    const num2 = Number(String(a2.amount).replace(/,/g, ""));
                    if (num1 != num2 || a1.bootDisable != a2.bootDisable) {
                      ReimbursementisShow = true;
                      throw new Error("EndIterative");
                    }
                  }
                });
              });
            });
          });
        } catch (e) {}
        if (ReimbursementisShow) {
          this.$confirm("商务费用管理已修改末保存, 是否继续切换?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //  确定
            })
            .catch(() => {
              // 取消
              this.activeName = "商务费用管理";
              this.oldData = this.activeName;
            });
        }
        this.oldData = this.activeName;
        return;
      }

      if (this.oldData == "项目费用管理") {
        try {
          this.usedRequestFundList.forEach((v) => {
            this.RequestFundList.forEach((k) => {
              v.ruleList.forEach((a1) => {
                k.ruleList.forEach((a2) => {
                  if (a1.id == a2.id) {
                    const num1 = Number(String(a1.amount).replace(/,/g, ""));
                    const num2 = Number(String(a2.amount).replace(/,/g, ""));
                    if (num1 != num2 || a1.bootDisable != a2.bootDisable) {
                      RequestFundisShow = true;
                      throw new Error("EndIterative");
                    }
                  }
                });
              });
            });
          });
        } catch (e) {}

        if (RequestFundisShow) {
          this.$confirm("项目费用管理已修改末保存, 是否继续切换?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //  确定
            })
            .catch(() => {
              // 取消
              this.activeName = "项目费用管理";
              this.oldData = this.activeName;
            });
        }
        this.oldData = this.activeName;
        return;
      }

      if (this.oldData == "公共费用管理") {
        try {
          this.PublicCostList.forEach((v) => {
            this.usedPublicCostList.forEach((k) => {
              v.ruleList.forEach((a1) => {
                k.ruleList.forEach((a2) => {
                  if (a1.id == a2.id) {
                    const num1 = Number(String(a1.amount).replace(/,/g, ""));
                    const num2 = Number(String(a2.amount).replace(/,/g, ""));
                    if (num1 != num2 || a1.bootDisable != a2.bootDisable) {
                      PublicCostisShow = true;
                      throw new Error("EndIterative");
                    }
                  }
                });
              });
            });
          });
        } catch (e) {}

        if (PublicCostisShow) {
          this.$confirm("公共费用管理已修改末保存, 是否继续切换?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //  确定
            })
            .catch(() => {
              // 取消
              this.activeName = "公共费用管理";
              this.oldData = this.activeName;
            });
        }
        this.oldData = this.activeName;
        return;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
.fullScreen {
  .cost {
    padding: 20px 0;
    /deep/.el-timeline-item__timestamp {
      font-size: 16px;
      font-weight: bold;
      color: #303133;
    }
  }
  .danwei {
    position: absolute;
    right: 2%;
  }
  /deep/#checkOpinion {
    .el-form-item__label {
      font-size: 16px !important;
      color: red !important;
    }
  }
  .Taxes {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding-left: 12px;
    border: 1px solid #ccc;
    border-radius: 7px;
  }
}
</style>
